<template>
  <div class=" sticky-top  z-1020 bg-white border-bottom shadow-sm">
    <div class="position-relative logo-bar-area z-1">
      <div class="container">
        <div class="d-flex align-items-center">

					<div class="col-auto col-xl-3 pl-0 pr-3 d-flex align-items-center">
						<a class="d-block py-20px mr-3 ml-0">
								<img :src="logoUrl"  class="mw-100 h-30px h-md-40px" height="40">
						</a>

						<!-- <div class="d-none d-xl-block align-self-stretch category-menu-icon-box ml-auto mr-0" v-if="showCategoryBtn" @mouseover="showModal" @mouseleave="hideModal">
								<div class="h-100 d-flex align-items-center" id="category-menu-icon">
										<div class="dropdown-toggle navbar-light bg-light h-40px w-50px pl-2 rounded border c-pointer">
												<span class="navbar-toggler-icon"></span>
										</div>
								</div>
						</div> -->
					</div>
					<!-- <div class="d-lg-none ml-auto mr-0">
							<a class="p-2 d-block text-reset" href="javascript:void(0);" data-toggle="class-toggle" data-target=".front-header-search">
									<i class="las la-search la-flip-horizontal la-2x"></i>
							</a>
					</div> -->
	<!-- front-header-search -->
					<div class="flex-grow-1  d-flex align-items-center bg-white">
						<div class="position-relative flex-grow-1">
							<form  class="stop-propagation">
								<div class="d-flex position-relative align-items-center">
									<!-- <div class="d-lg-none" data-toggle="class-toggle" data-target=".front-header-search">
											<button class="btn px-2" type="button"><i class="la la-2x la-long-arrow-left"></i></button>
									</div> -->
									<div class="input-group">
										<input type="text" class=" border-lg form-control" @change="changeKeyword" @input="changeKeyword" @click.stop="defaultEvent" @focus.stop="checkResult" v-model="keyword"   :placeholder="$t('wo-shi-lai-mai-shi-mo-de')" autocomplete="off">
										<div class="input-group-append d-none d-lg-block">
											<button class="btn btn-primary" type="button" @click="searchGoods">
												<i class="la la-search la-flip-horizontal fs-18"></i>
											</button>
										</div>
									</div>
								</div>
							</form>
							<div class="typed-search-box stop-propagation document-click-d-none  bg-white rounded shadow-lg position-absolute left-0 top-100 w-100" :class="showResult ? 'show' : 'd-none'" style="min-height: 200px">
								<div class="search-preloader absolute-top-center">
									<div class="dot-loader">
									</div>
								</div>
								<div class="search-nothing  p-3 text-center fs-16" :class="searchCategoryList.length == 0 && searchProductList.length == 0 && searchShopList.length == 0 ? '' : 'd-none'">
                  {{ $t('bao-qian-zhao-bu-dao') }}“{{keyword}}”
								</div>
								<div id="search-content" class="text-left">

									<div class="" v-if="searchCategoryList.length > 0">
										<div class="px-2 py-1 text-uppercase fs-10 text-right text-muted bg-soft-secondary">{{ $t('fen-lei-tui-jian')}}</div>
										<ul class="list-group list-group-raw">
											<li class="list-group-item py-1" v-for="(item, i) in searchCategoryList" :key="i">
												<a class="text-reset hov-text-primary" @click.stop="toCategoryDetail(item)">{{ item.Name }}</a>
											</li>
										</ul>
									</div>

									<div class="" v-if="searchProductList.length > 0">
										<div class="px-2 py-1 text-uppercase fs-10 text-right text-muted bg-soft-secondary">产品</div>
										<ul class="list-group list-group-raw">
											<li class="list-group-item" v-for="(item, i) in searchProductList" :key="i">
												<a class="text-reset" @click.stop="toProductDetail(item)">
													<div class="d-flex search-product align-items-center">
														<div class="mr-3">
															<img class="size-40px img-fit rounded" :src="item.ImgUrl">
														</div>
														<div class="flex-grow-1 overflow--hidden minw-0">
															<div class="product-name text-truncate fs-14 mb-5px">
																{{item.GoodsName}}
															</div>
															<div class="">
																<span class="fw-600 fs-16 text-primary">{{ resetPrice(item.Price) }}</span>
															</div>
														</div>
													</div>
												</a>
											</li>
										</ul>
									</div>

									<div class="" v-if="searchShopList.length > 0">
										<div class="px-2 py-1 text-uppercase fs-10 text-right text-muted bg-soft-secondary">店铺</div>
										<ul class="list-group list-group-raw">
											<li class="list-group-item" v-for="(item, i) in searchShopList" :key="i">
												<a class="text-reset" @click.stop="toShop(item)">
													<div class="d-flex search-product align-items-center">
														<div class="mr-3">
																<img class="size-40px img-fit rounded" :src="item.Logo || defaultUrl">
														</div>
														<div class="flex-grow-1 overflow--hidden">
																<div class="product-name text-truncate fs-14 mb-5px">
																	{{item.Name}}
																</div>
																<div class="opacity-60">
																	{{item.Address}}
																</div>
														</div>
													</div>
												</a>
											</li>
										</ul>
									</div>

								</div>
							</div>
						</div>
					</div>

					<div class="d-none d-lg-none ml-3 mr-0">
						<div class="nav-search-box">
							<a  class="nav-box-link">
								<i class="la la-search la-flip-horizontal d-inline-block nav-box-icon"></i>
							</a>
						</div>
					</div>

					<!-- <div class="d-none d-lg-block ml-3 mr-0">
							<div class="" id="compare">
							<a class="d-flex align-items-center text-reset">
								<i class="la la-refresh la-2x opacity-80"></i>
								<span class="flex-grow-1 ml-1">
									<span class="badge badge-primary badge-inline badge-pill">0</span>
									<span class="nav-box-text d-none d-xl-block opacity-70">相比</span>
								</span>
							</a>
						</div>
					</div> -->

					<div class="d-none d-lg-block ml-3 mr-0">
						<div class="" id="wishlist">
							<a  class="d-flex align-items-center text-reset" @click="toWish">
								<i class="la la-heart-o la-2x opacity-80"></i>
								<span class="flex-grow-1 ml-1">
									<span class="badge badge-primary badge-inline badge-pill">{{wishList.length}}</span>
									<span class="nav-box-text d-none d-xl-block opacity-70">{{ $t('yuan-wang-qing-dan') }}</span>
								</span>
							</a>
						</div>
					</div>

					<div class="d-none d-lg-block  align-self-stretch ml-3 mr-0" data-hover="dropdown">
						<div class="nav-cart-box dropdown h-100" id="cart_items">
							<a href="javascript:void(0)" class="d-flex align-items-center text-reset h-100" @click.stop="changeShowCar">
								<i class="la la-shopping-cart la-2x opacity-80"></i>
								<span class="flex-grow-1 ml-1">
									<span class="badge badge-primary badge-inline badge-pill cart-count">{{cartList.length}}</span>
									<span class="nav-box-text d-none d-xl-block opacity-70">{{ $t('gou-wu-che') }}</span>
								</span>
							</a>
							<!-- <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg p-0 stop-propagation" :class="showCar ? 'show' : ''">
								<div class="text-center p-3">
									<i class="las la-frown la-3x opacity-60 mb-3"></i>
									<h3 class="h6 fw-700">您的购物车是空的</h3>
								</div>
							</div> -->

							<div class="dropdown-menu dropdown-menu-right dropdown-menu-lg p-0 stop-propagation" :class="showCar ? 'show' : ''">
								<template v-if="cartList.length > 0">
									<div class="p-3 fs-15 fw-600 p-3 border-bottom">
										{{ $t('gou-wu-che-wu-pin') }}	</div>
									<ul class="h-250px overflow-auto c-scrollbar-light list-group list-group-flush">
										<li class="list-group-item" v-for="(item, i) in cartList" :key="i">
											<span class="d-flex align-items-center">
													<a  class="text-reset d-flex align-items-center flex-grow-1">
														<img :src="item.Goods.ImgUrl" class="img-fit lazyload size-60px rounded" >
														<span class="minw-0 pl-2 flex-grow-1">
															<span class="fw-600 mb-1 text-truncate-2">
																{{item.Goods.GoodsName}}
															</span>
															<span class="">1x</span>
															<span class="">{{resetPrice(skuPrice(item))}}</span>
														</span>
													</a>
													<span class="">
														<button  class="btn btn-sm btn-icon stop-propagation" @click.stop="removeGoods(item)">
															<i class="la la-close"></i>
														</button>
													</span>
											</span>
										</li>
									</ul>
									<div class="px-3 py-2 fs-15 border-top d-flex justify-content-between">
										<span class="opacity-60">{{ $t('xiao-ji-1') }}</span>
										<span class="fw-600">{{resetPrice(getTotal())}}</span>
									</div>
									<div class="px-3 py-2 text-center border-top">
										<ul class="list-inline mb-0">
											<li class="list-inline-item" @click="toPath('cart')">
												<a  class="btn btn-soft-primary btn-sm">
													{{ $t('cha-kan-gou-wu-che') }}	</a>
											</li>
											<li class="list-inline-item" @click="toPath('cart')">
												<a href="javascript: void(0)"  class="btn btn-primary btn-sm">
													{{ $t('cha-kan') }}	</a>
											</li>
										</ul>
									</div>
								</template>
								<div class="text-center p-3" v-if="cartList.length == 0">
										<i class="las la-frown la-3x opacity-60 mb-3"></i>
										<h3 class="h6 fw-700">{{ $t('nin-de-gou-wu-che-shi-kong-de') }}</h3>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="hover-category-menu position-absolute w-100 top-100 left-0 right-0  z-3 " :class="showCategory ? 'active' : 'd-none'" id="hover-category-menu" >
				<div class="container">
					<div class="row gutters-10 position-relative">
						<div class="col-lg-3 position-static" @mousemove="clearTime" @mouseleave="hideModal">
							<div class="aiz-category-menu bg-white rounded shadow-sm" id="category-sidebar">
								<div class="p-3 d-none d-lg-block rounded-top all-category position-relative text-left">
									<span class="fw-600 fs-16 mr-3">类别</span>
									<a  class="text-reset">
											<span class="d-none d-lg-inline-block">看到所有 ></span>
									</a>
								</div>
								<ul class="list-unstyled categories no-scrollbar py-2 mb-0 text-left">
									<li class="category-nav-element" v-for="(item, i) in categoryList" :key="i">
										<a class="text-truncate text-reset py-2 px-3 d-block">
											<img  class="cat-image lazyload mr-2 opacity-60" :src="item.url" width="16">
											<span class="cat-name">{{item.name}}</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div> -->
    </div>
		<div class="bg-white border-top border-gray-200 py-1">
			<div class="container">
				<ul class="list-inline mb-0 pl-0 mobile-hor-swipe text-center">
					<li class="list-inline-item mr-0" v-for="(item, i) in menuList" :key="i">
						<a  class="opacity-60 fs-14 px-3 py-2 d-inline-block fw-600 hov-opacity-100 text-reset" @click="toPath(item.path)">
							{{item.name}}
						</a>
					</li>
				</ul>
			</div>
		</div>
  </div>
</template>
<script>
import { resetPrice } from '@/utils/common'
import { getUserType } from '@/utils/auth'
import { Popup, Icon, Button } from 'vant'
import { getCartList, cardRemove, commonSearch } from '@/api/index'
export default {
  name: 'pageHeader',
  components: {
    Popup,
    Icon,
    Button
  },
  data() {
    return {
      // logoUrl: "",
      logoUrl1: require('../../assets/imgs/logo.png'),
			defaultUrl: require('../../assets/imgs/avatar-place.png'),
			showCar: false,
			showCategory: false,
			showCategoryBtn: true,
			categoryList: [],
			timer: '',
			keyword: '',
			searchCategoryList: [],
			searchProductList: [],
			searchShopList: [],
			showResult: false
    }
  },
	computed: {
		bodyClick() {
			return this.$store.state.bodyClick
		},
    logoUrl() {
      return this.$store.state.logo.length > 0
          ? "/upload/tmp/" + this.$store.state.logo
          : this.logoUrl1;
    },
		cartList() {
			return this.$store.state.cartList
		},
		wishList() {
			return this.$store.state.wishList
		},
		isLogin() {
			return this.$store.state.isLogin
		},
		userType() {
			return getUserType()
		},
		menuList() {
			return [
				{
					name: this.$t('shou-ye'),
					path: 'index'
				},
				{
					name: this.$t('suo-you-lei-bie-0'),
					path: 'category'
				},
				{
					name: this.$t('suo-you-mai-jia'),
					path: 'seller'
				},
        {
          name: this.$t('zai-xian-ke-fu'),
          path: 'kefu'
        },
			]
		}
	},
	watch: {
		bodyClick() {
			this.clearClick()
		},
		isLogin(val) {
			if (val) {
				this.checkAuth()
			}
		},
		userType(val) {
			this.checkAuth()
		},
		$route(val) {
			this.showCategoryBtn = val.name != 'index'
		},
	},
  mounted() {
		if (this.isLogin && this.userType == 'custom') {
			this.init()
		}
  },
  methods: {
		resetPrice,
		checkAuth() {
			if (this.isLogin && this.userType == 'custom') {
				this.init()
			}
		},
    // 获取规格价格
    skuPrice(item){
      try {
        let SaleSpecifications = JSON.parse(item.Goods.SaleSpecifications)
        let SkuPropId = item.SkuPropId
        if(SaleSpecifications.price === undefined) {
          return item.Goods.Price
        }
        for(var i in SaleSpecifications.price){
          let price = SaleSpecifications.price[i]
          if(price.skuPropIds == SkuPropId){
            return price.skuVal.skuAmount.value
          }
        }
      } catch (e) {
        return item.Goods.Price
      }
    },
		init() {
			let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 100)
      getCartList(form).then(res => {
				this.$store.state.cartList = res.data.Items.filter(v => {
          return v.Attribute == 1
        })
				this.$store.state.wishList = res.data.Items.filter(v => {
          return v.Attribute == 2
        })
			})
		},
		changeShowCar() {
			this.showCar = !this.showCar
		},
		clearClick() {
			this.showCar = false
			this.showResult = false
		},
		toPath(path) {
      if(path == "kefu") {
        this.$kefu()
        return
      }
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
		showModal() {
			this.clearTime()
			this.showCategory = true
		},
		hideModal() {
			this.timer = setTimeout(() => {
				this.showCategory = false
			}, 400)
		},
		clearTime() {
			clearTimeout(this.timer)
		},
		getTotal() {
			let sum = 0
			this.cartList.forEach(v => {
				sum += parseFloat(this.skuPrice(v))
			})
			return sum
		},
		removeGoods(data) {
			let form = new FormData()
			form.append('ID', data.ID)
			cardRemove(form).then(res => {
				if (res.code == 0) {
					this.$toast(this.$t('shan-chu-cheng-gong'))
					this.init()
				} else {
					this.$toast.fail(res.msg)
				}
			})
		},
		toWish() {
			if (!this.isLogin) {
				this.$router.push({
					name: 'login'
				})
				return
			}
			if (this.userType == 'custom') {
				this.toPath('wishlist')
			}
		},
		changeKeyword() {
			clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				this.searchGoods()
			}, 400)
		},
		searchGoods() {
			if (!this.keyword) {
				clearTimeout(this.timer)
				return
			}
			let form = new FormData()
			form.append('Keyword', this.keyword)
			commonSearch(form).then(res => {
				this.showResult = true
				this.searchCategoryList = res.data.Category || []
				this.searchProductList = res.data.Product || []
				this.searchShopList = res.data.Shop || []
			})
		},
		clearSearch() {
			clearTimeout(this.timer)
			this.showResult = false
			this.keyword = ''
			this.searchCategoryList = []
			this.searchProductList = []
			this.searchShopList = []
		},
		toShop(data) {
			this.clearSearch()
			this.$router.push({
        name: 'shopInfo',
        query: {
          id: data.ID
        }
      })
		},
		toProductDetail(data) {
			this.clearSearch()
			this.$router.push({
        name: 'product',
        query: {
          id: data.ID
        }
      })
		},
		toCategoryDetail(data) {
			this.clearSearch()
			this.$router.push({
        name: 'categoryDetail',
        query: {
          name: data.Name,
          id: data.ID
        }
      })
		},
		checkResult() {
			if (this.searchCategoryList.length > 0 || this.searchProductList.length > 0 || this.searchShopList.length > 0) {
				this.showResult = true
			}
		},
		defaultEvent() {

		}
  }
}
</script>