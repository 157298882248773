<template>
  <div class="message-modal">
    <div class="title">
      在线客服
      <Icon name="cross" class="icon" size="16px" @click="cancel"></Icon>
    </div>

    <div class="content">
      <div class="message" v-for="(item, i) in messageList" :key="i">
        <div class="header" v-if="item.FromUId == kefuUID">
          <img :src="kefuUrl" alt="">
        </div>
        <div class="flex-1" :class="item.FromUId == kefuUID ? '' : 'end'">
          <div class="name" v-if="item.FromUId == kefuUID">
            {{item.ToUser.FullName}} {{item.CreatedAt}}
          </div>
          <div class="name" v-if="item.ToUId == kefuUID">
            {{userInfo.FullName}} {{item.CreatedAt}}
          </div>
          <div class="content" >
            {{item.Content}}
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <textarea rows="2" v-model="content"></textarea>
      <div class="button" @click="sendMsg">
        <img :src="sendUrl" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import { initWebSocket } from '@/utils/common'
import { Icon } from 'vant'
import { getToken } from '@/utils/auth'
import { chatList } from '@/api/index'
import moment from 'moment'
export default {
  components: {
    Icon
  },
  data() {
    return {
      sendUrl: require('../../assets/imgs/send.svg'),
      kefuUrl: require('../../assets/imgs/kefu.jpg'),
      messageList: [],
      webSocket: '',
      content: ''
    }
  },
  computed: {
    kefuUID() {
      return this.$store.state.kefuUID
    },
    showKefuModal() {
      return this.$store.state.showKefuModal
    },
    userInfo() {
      return this.$store.state.userInfo
    },
  },
  mounted() {
    if (this.kefuUID && this.showKefuModal) {
      this.initSocket()
      this.init()
    }
  },
  methods: {
    init() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 20)
      form.append('to_uid', this.kefuUID)
      chatList(form).then(res => {
        this.messageList = res.data.Items.reverse()
        setTimeout(() => {
          document.querySelector('.content').scrollTo({top: 10000})
        }, 300)
      })
    },
    async initSocket(){ //初始化weosocket
      let client = await initWebSocket(this.websocketonmessage, this.initSocket)
      this.webSocket = client
    },
    websocketonmessage(e) {
      let redata = {}
      if (e.data.indexOf('Welcome') > -1) {
				this.webSocket.send(JSON.stringify({
          action: 'login',
          to: '',
          message: getToken()
        }))
			}
      try {
        redata = JSON.parse(e.data)
        this.setListData(redata)
      }
      catch(e) {
      }
    },
    setListData(data) {
      this.messageList.push(data)
      document.querySelector('.content').scrollTo({top: 10000})
    },
    sendMsg() {
      if (!this.content) {
        this.$toast.fail('消息不能为空')
        return
      }
      let content = {
        action: 'publish',
        to: this.kefuUID + '',
        message: this.content
      }
      this.webSocket.send(JSON.stringify(content))
      this.messageList.push({
        ToUId: this.kefuUID,
        FromUId: this.userInfo.UserAccount.UId,
        CreatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        FullName: this.userInfo.FullName,
        Content: this.content
      })
      this.content = ''
    },
    cancel() {
      this.$store.state.showKefuModal = false
      this.webSocket.close()
    }
  }
}
</script>