<template>
  <section class="bg-white border-top mt-auto">
    <div class="container">
      <div class="row no-gutters">
        <div class="col-lg-3 col-md-6">
          <a class="text-reset border-left text-center p-4 d-block" @click="toPath('terms')">
            <i class="la la-file-text la-3x text-primary mb-2"></i>
            <h4 class="h6">{{ $t('tiao-kuan-he-tiao-jian') }}</h4>
          </a>
        </div>
        <div class="col-lg-3 col-md-6">
            <a class="text-reset border-left text-center p-4 d-block" @click="toPath('returnPolicy')">
              <i class="la la-mail-reply la-3x text-primary mb-2"></i>
              <h4 class="h6">{{ $t('tui-huo-zheng-ce') }}</h4>
            </a>
        </div>
        <div class="col-lg-3 col-md-6">
          <a class="text-reset border-left text-center p-4 d-block" @click="toPath('supportPolicy')">
            <i class="la la-support la-3x text-primary mb-2"></i>
            <h4 class="h6">{{ $t('zhi-chi-zheng-ce') }}</h4>
          </a>
        </div>
        <div class="col-lg-3 col-md-6">
          <a class="text-reset border-left border-right text-center p-4 d-block" @click="toPath('privacyPolicy')">
            <i class="las la-exclamation-circle la-3x text-primary mb-2"></i>
            <h4 class="h6">{{ $t('yin-si-ce-lve') }}</h4>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'tabbar',
  props:{

  },
  data() {
    return {
      homeIcon: require('../../assets/imgs/tab/index.png'),
      
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
     toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
  }
}
</script>