<template>
  <footer class="pt-3 pb-7 pb-xl-3 bg-black text-light">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4">
            <div class="text-center text-md-left">
            </div>
        </div>
        <div class="col-lg-4">
            <ul class="list-inline my-3 my-md-0 social colored text-center">
                <li class="list-inline-item">
                  <a  target="_blank"  :href="`https://facebook.com/sharer/sharer.php?u=${shareLink}`"  class="facebook"><i class="lab la-facebook-f"></i></a>
                </li>
                <li class="list-inline-item">
                  <a  target="_blank" :href="`https://twitter.com/share?url=${shareLink}`" class="twitter"><i class="lab la-twitter"></i></a>
                </li>
                <li class="list-inline-item" >
                  <a  target="_blank" :href="`whatsapp://send?text=${shareLink}`" class="instagram"><i class="lab la-whatsapp"></i></a>
                </li>
                <li class="list-inline-item">
                  <a  target="_blank" :href="`mailto:?&amp;body=${shareLink}`" class="youtube"><i class="lar la-envelope"></i></a>
                </li>
                <li class="list-inline-item">
                  <a  target="_blank"  :href="`https://www.linkedin.com/shareArticle?mini=true&amp;url=${shareLink}`" class="linkedin"><i class="lab la-linkedin-in"></i></a>
                </li>
            </ul>
        </div>
        <div class="col-lg-4">
          <div class="text-center text-md-right">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <img :src="linkUrl" height="30" class="mw-100 h-auto" style="max-height: 30px">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'tabbar',
  props:{

  },
  data() {
    return {
      linkUrl: require('../../assets/imgs/link.png'),
      shareLink: ''
    }
  },
  watch: {
  },
  mounted() {
    this.shareLink = window.location.href
  },
  methods: {
    
  }
}
</script>