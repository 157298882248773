<template>
  <div  class="top-navbar bg-white border-bottom border-soft-secondary z-1035" >
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col">
          <ul class="list-inline d-flex justify-content-between justify-content-lg-start mb-0">
            <li class="list-inline-item dropdown mr-3" id="lang-change">
              <a class="dropdown-toggle text-reset py-2" @click.stop="changeShow">
                <img :src="langUrl"  class="mr-2 lazyload"  height="11">
                <span class="opacity-60">{{langName}}</span>
              </a>
              <ul class="dropdown-menu dropdown-menu-left" :class="showLang ? 'show' : ''">
                <li>
                  <a   class="dropdown-item" :class="lang == item.key ? 'active' : ''" v-for="(item, i) in langList" :key="i" @click.stop="chooseLang(item)">
                    <img :src="item.url"  class="mr-1 lazyload"  height="11">
                    <span class="language">{{item.name}}</span>
                  </a>
                </li>
              </ul>
            </li>

            <li class="list-inline-item mr-3  border-left-0 pr-3 pl-0 kefu-btn show-xs">
              <a href="javascript:void(0)" @click="$kefu" class="text-reset d-inline-block opacity-60 py-2">
                <span>{{ $t('zai-xian-ke-fu') }}</span>
              </a>
            </li>

            <li class="list-inline-item dropdown ml-auto ml-lg-0 mr-0" id="currency-change">
              <a href="javascript:void(0)" class="dropdown-toggle text-reset py-2 opacity-60" @click.stop="changeCurrent">
                {{currentName}} {{currencySymbol}}
              </a>
              <ul class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left" :class="showCurrent ? 'show' : ''">
                <li v-for="(item, i) in currentList" :key="i" @click.stop="chooseCurrent(item)">
                  <a class="dropdown-item " :class="item.Name == currentName ? 'active' : ''" href="javascript:void(0)">{{item.Name}}({{item.Symbol}})</a>
                </li>
              </ul>
            </li>

          
          </ul>
        </div>

         <div class="col-5 text-right d-none d-lg-block">
            <ul class="list-inline mb-0 h-100 d-flex justify-content-end align-items-center">
                <li class="list-inline-item mr-3 border-right border-left-0 pr-3 pl-0 ">
                  <a href="javascript:void(0)" @click="$kefu" class="text-reset d-inline-block opacity-60 py-2">
                    <span>{{ $t('zai-xian-ke-fu') }}</span>
                  </a>
                </li>
                <li class="list-inline-item mr-3 border-right border-left-0 pr-3 pl-0" @click="toLogin" v-if="!isLogin">
                  <a  class="text-reset d-inline-block opacity-60 py-2">{{ $t('deng-lu') }}</a>
                </li>
                <li class="list-inline-item" v-if="!isLogin">
                  <a  class="text-reset d-inline-block opacity-60 py-2" @click="toRegister">{{ $t('zhu-ce') }}</a>
                </li>

                <li class="list-inline-item mr-3 border-right border-left-0 pr-3 pl-0 dropdown" v-if="isLogin">
                    <a class="dropdown-toggle no-arrow text-reset" @click.stop="changeShowNotice">
                      <span class="">
                        <span class="position-relative d-inline-block">
                          <i class="las la-bell fs-18"></i>
                          <span v-if="noticeList.length > 0" class="badge badge-sm badge-dot badge-circle badge-primary position-absolute absolute-top-right"></span>
                        </span>
                      </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg py-0 " :class="showNotice ? 'show' : ''">
                      <div class="p-3 bg-light border-bottom">
                        <h6 class="mb-0">{{ $t('tong-zhi') }}</h6>
                      </div>
                      <div class="px-3 c-scrollbar-light overflow-auto " style="max-height:300px;" >
                          <ul class="list-group list-group-flush" >
                            <li class="list-group-item" v-for="(item, i) in noticeList" :key="i">
                              <a  class="text-reset">
                                <span class="ml-2">
                                  {{item.Content}}
                                </span>
                              </a>
                            </li>
                            <li class="list-group-item" v-if="noticeList.length == 0">
                              <div class="py-4 text-center fs-16">
                                {{ $t('wei-zhao-dao-tong-zhi') }} </div>
                            </li>
                        </ul>
                      </div>
                      <div class="text-center border-top">
                        <a  class="text-reset d-block py-2" @click="toPath('notice')">{{ $t('cha-kan-suo-you-tong-zhi') }} </a>
                      </div>
                  </div>
                </li> 

                <li class="list-inline-item mr-3 border-right border-left-0 pr-3 pl-0" v-if="isLogin" @click="toDash">
                  <a  class="text-reset d-inline-block opacity-60 py-2">{{ $t('wo-de-mian-ban') }}</a>
                </li>
                <li class="list-inline-item" v-if="isLogin" @click="logout">
                    <a  class="text-reset d-inline-block opacity-60 py-2">{{ $t('tui-chu') }}</a>
                </li>
               
            </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Popup, Icon, Button } from 'vant'
import { getDefaultLanguage } from '@/utils/common';
import { removeToken, getUserType, removeUserType } from '@/utils/auth';
import { getCurrentList } from '@/api/user';
import { getNoticeList } from '@/api/index';
export default {
  name: 'pageHeader',
  components: {
    Popup,
    Icon,
    Button
  },
  data() {
    return {
      lang: '',
      langName: '',
      langUrl: '',
      langList: [],
      showLang: false,
      showNotice: false,
      langArrBak: [
        {
          url: require('../../assets/imgs/lang/zh.png'),
          name: '简体中文',
          key: 'zh-CN',
        },
        {
          url: require('../../assets/imgs/lang/zh.png'),
          name: '繁体中文',
          key: 'zh-TW',
        },
        {
          url: require('../../assets/imgs/lang/en.png'),
          name: 'English',
          key: 'en',
        },
          // 哈萨克斯坦语
        {
          url: require('../../assets/imgs/lang/kk.png'),
          name: 'Русский',
          key: 'kk',
        },
        //   西班牙语
        {
          url: require('../../assets/imgs/lang/es.png'),
          name: 'Español',
          key: 'es',
        },
          // 法语
        {
          url: require('../../assets/imgs/lang/fr.png'),
          name: 'Français',
          key: 'fr',
        },
          // 德语
        {
          url: require('../../assets/imgs/lang/de.png'),
          name: 'Deutsch',
          key: 'de',
        },
          // 意大利语
        {
          url: require('../../assets/imgs/lang/it.png'),
          name: 'Italiano',
          key: 'it',
        },
          // 葡萄牙语
        {
          url: require('../../assets/imgs/lang/pt.png'),
          name: 'Português',
          key: 'pt',
        },
          // 俄语
        {
          url: require('../../assets/imgs/lang/ru.png'),
          name: 'Pусский',
          key: 'ru',
        },
        //   土耳其语
        {
          url: require('../../assets/imgs/lang/tr.png'),
          name: 'Türkçe',
          key: 'tr',
        },
          // 韩语
        {
          url: require('../../assets/imgs/lang/ko.png'),
          name: '한국어',
          key: 'ko',
        },
          // 日语
        {
          url: require('../../assets/imgs/lang/ja.png'),
          name: '日本語',
          key: 'ja',
        },
          // 越南语
        {
          url: require('../../assets/imgs/lang/vi.png'),
          name: 'Tiếng Việt',
          key: 'vi',
        },
          // 印尼语
        {
          url: require('../../assets/imgs/lang/id.png'),
          name: 'Bahasa Indonesia',
          key: 'id',
        },
          // 马来语
        {
          url: require('../../assets/imgs/lang/ms.png'),
          name: 'Bahasa Melayu',
          key: 'ms',
        },
          // 泰语
        {
          url: require('../../assets/imgs/lang/th.png'),
          name: 'ภาษาไทย',
          key: 'th',
        },
          // 阿拉伯语
        {
          url: require('../../assets/imgs/lang/ar.png'),
          name: 'العربية',
          key: 'ar',
        },
          // 希伯来语
        {
          url: require('../../assets/imgs/lang/he.png'),
          name: 'עברית',
          key: 'he',
        },
          // 印地语
        {
          url: require('../../assets/imgs/lang/hi.png'),
          name: 'हिन्दी',
          key: 'hi'
        },
          // 荷兰语，波斯语，瑞典语，乌克兰语，罗马利亚语，阿拉伯语，乌尔都语，希腊语，匈牙利语，波兰语，丹麦语，保加利亚语，俄语，芬兰语，捷克语，泰语
          // 波斯语
        {
          url: require('../../assets/imgs/lang/fa.png'),
          name: 'فارسی',
          key: 'fa',
        },
          // 乌克兰语
        {
          url: require('../../assets/imgs/lang/uk.png'),
          name: 'Українська',
          key: 'uk',
        },
          // 乌尔都语
        {
          url: require('../../assets/imgs/lang/ur.png'),
          name: 'اردو',
          key: 'ur',
        },
          // 希腊语
        {
          url: require('../../assets/imgs/lang/el.png'),
          name: 'Ελληνικά',
          key: 'el',
        },
          // 匈牙利语
        {
          url: require('../../assets/imgs/lang/hu.png'),
          name: 'Magyar',
          key: 'hu',
        },
          // 波兰语
        {
          url: require('../../assets/imgs/lang/pl.png'),
          name: 'Polski',
          key: 'pl',
        },
          // 丹麦语
        {
          url: require('../../assets/imgs/lang/da.png'),
          name: 'Dansk',
          key: 'da',
        },
          // 保加利亚语
        {
          url: require('../../assets/imgs/lang/bg.png'),
          name: 'Български',
          key: 'bg',
        },
          // 俄语，芬兰语，捷克语，泰语
          // 芬兰语
        {
          url: require('../../assets/imgs/lang/fi.png'),
          name: 'Suomi',
          key: 'fi',
        },
          // 捷克语
        {
          url: require('../../assets/imgs/lang/cs.png'),
          name: 'Čeština',
          key: 'cs',
        },
          // 荷兰语
        {
          url: require('../../assets/imgs/lang/nl.png'),
          name: 'Nederlands',
          key: 'nl',
        },
          // 瑞典语
        {
          url: require('../../assets/imgs/lang/sv.png'),
          name: 'Svenska',
          key: 'sv',
        },
          // 罗马利亚语
        {
          url: require('../../assets/imgs/lang/ro.png'),
          name: 'Română',
          key: 'ro',
        },
          // 缅甸语
        {
          url: require('../../assets/imgs/lang/mm.png'),
          name: 'မြန်မာ',
          key: 'my',
        }

      ],
      currentName: '',
      currencySymbol: '',
      showCurrent: false,
      currentList: [],
      onlineNumber: '400-600-2668'
    }
  },
  computed: {
		bodyClick() {
			return this.$store.state.bodyClick
		},
		isLogin() {
			return this.$store.state.isLogin
		},
		noticeList() {
			return this.$store.state.noticeList
		},
		userType() {
			return getUserType()
		},
	},
	watch: {
		bodyClick() {
			this.clearClick()
		},
    isLogin(val) {
      if (val) {
        this.initNotice()
      }
    }
	},
  mounted() {
    this.init()
    let locale = localStorage.getItem('locale')
    const defaultLanguage = getDefaultLanguage()
    this.lang = locale ? locale : defaultLanguage

    this.langList = this.langArrBak

    let index = this.langList.findIndex(v => {
      return v.key == this.lang
    })
    if (index === -1) {
      index = 0
    }
    this.langUrl = this.langList[index].url
    this.langName = this.langList[index].name
    this.lang = this.langList[index].key

    this.i18n.locale = this.lang
    this.$store.state.language = this.lang
  },
  methods: {
    init() {
      getCurrentList().then(res => {
        if (res.code == 0) {
          this.currentList = res.data.Items
          if (this.currentList.length > 0) {
            let symbol = localStorage.getItem('moneySymbol')
            let index = this.currentList.findIndex(v => {
              return v.Symbol == symbol
            })
            if (index === -1) {
              index = 0
            }
            this.currentName = this.currentList[index].Name
            this.currencySymbol = this.currentList[index].Symbol
            this.$store.state.moneySymbol = this.currencySymbol
            this.$store.state.moneyRate = this.currentList[index].Rate
          }
        }
      })
      if (this.isLogin) {
        this.initNotice()
      }

    },
    initNotice() {
      getNoticeList().then(res => {
        this.$store.state.noticeList = res.data.Items
      })
    },
    changeShow() {
      this.showLang = !this.showLang
      this.showCurrent = false
    },
    chooseLang(data) {
      this.lang = data.key
      this.langUrl = data.url
      this.langName = data.name
      this.showLang = false
      localStorage.setItem('locale', data.key)
      localStorage.setItem('localeName', data.name)
      this.i18n.locale = data.key
      this.$store.state.language = data.key
      window.location.reload()
    },
    changeCurrent() {
      this.showLang = false
      this.showCurrent = !this.showCurrent
    },
    chooseCurrent(data) {
      this.currentName = data.Name
      this.currencySymbol = data.Symbol
      this.showCurrent = false

      this.$store.state.moneySymbol = data.Symbol
      this.$store.state.moneyRate = data.Rate
      localStorage.setItem('moneySymbol', data.Symbol)
      localStorage.setItem('moneyRate', data.Rate)
    },
    changeShowNotice() {
      this.showNotice = !this.showNotice
    },
    clearClick() {
      this.showCurrent = false
      this.showLang = false
      this.showNotice = false
    },
    toLogin() {
      this.$router.push({
        name: 'login'
      })
    },
    toRegister() {
      this.$router.push({
        name: 'register'
      })
    },
    logout() {
      removeToken()
      removeUserType()
      this.$store.state.isLogin = false
      this.$router.push({
        name: 'index'
      })
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    toDash() {
      this.$router.push({
        name: this.userType == 'custom' ? 'dashboard' : 'sellerDashboard'
      })
    }
  }
}
</script>