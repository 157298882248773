<template>
<div>
    <div class="aiz-mobile-bottom-nav d-xl-none fixed-bottom bg-white shadow-lg border-top rounded-top auto-bottom-padding" style="box-shadow: 0px -1px 10px rgb(0 0 0 / 15%)!important; ">
      <div class="row align-items-center gutters-5">
        <div class="col">
          <a  class="text-reset d-block text-center pb-2 pt-3" @click="toPath('index')">
            <i class="las la-home fs-20 opacity-60 " :class="tabIndex == 1 ? 'opacity-100 text-primary' : ''"></i>
            <span class="d-block fs-10 fw-600 opacity-60 line-1 word-break " :class="tabIndex == 1 ? 'opacity-100 fw-600' : ''">{{ $t('shou-ye') }}</span>
          </a>
        </div>
        <div class="col">
          <a class="text-reset d-block text-center pb-2 pt-3" @click="toPath('category')">
            <i class="las la-list-ul fs-20 opacity-60 " :class="tabIndex == 2 ? 'opacity-100 text-primary' : ''"></i>
            <span class="d-block fs-10 fw-600 opacity-60  line-1 word-break " :class="tabIndex == 2 ? 'opacity-100 fw-600' : ''">{{ $t('fen-lei') }}</span>
          </a>
        </div>
        <div class="col-auto">
          <a  class="text-reset d-block text-center pb-2 pt-3" @click="toPath('cart')">
            <span class="align-items-center bg-primary border border-white border-width-4 d-flex justify-content-center position-relative rounded-circle size-50px" style="margin-top: -33px;box-shadow: 0px -5px 10px rgb(0 0 0 / 15%);border-color: #fff !important; left: 50%; transform: translateX(-50%)">
              <i class="las la-shopping-bag la-2x text-white"></i>
            </span>
            <span class="d-block mt-1 fs-10 fw-600 opacity-60  line-1 word-break " :class="tabIndex == 3 ? 'opacity-100 fw-600' : ''" >
              {{ $t('gou-wu-che') }}(<span class="cart-count">{{cartList.length}}</span>)
            </span>
          </a>
        </div>
        <div class="col">
          <a  class="text-reset d-block text-center pb-2 pt-3" @click="toPath('notice')">
            <span class="d-inline-block position-relative px-2">
              <i class="las la-bell fs-20 opacity-60 " :class="tabIndex == 4 ? 'opacity-100 text-primary' : ''"></i>
              <span v-if="noticeList.length > 0" class="badge badge-sm badge-dot badge-circle badge-primary position-absolute absolute-top-right" style="right: 7px;top: -2px;"></span>
            </span>
            <span class="d-block fs-10 fw-600 opacity-60  line-1 word-break "  :class="tabIndex == 4 ? 'opacity-100 fw-600' : ''">{{ $t('tong-zhi') }}</span>
          </a>
        </div>
        <div class="col">
          <a  class="text-reset d-block text-center pb-2 pt-3" @click="toAccount">
            <span class="d-block mx-auto">
              <img :src="headerUrl" class="rounded-circle size-20px">
            </span>
            <span class="d-block fs-10 fw-600 opacity-60  line-1 word-break ">{{ $t('zhang-hu') }}</span>
          </a>
        </div>
      </div>
    </div>

    <div class="aiz-mobile-side-nav collapse-sidebar-wrap sidebar-xl d-xl-none z-1035" :class="showLeftMenu ? 'active' : ''">
      <div class="overlay dark c-pointer overlay-fixed" @click="showMenu"></div>
      <div class="collapse-sidebar bg-white">
         <div class="aiz-user-sidenav-wrap position-relative z-1 shadow-sm">
            <div class="aiz-user-sidenav rounded overflow-auto c-scrollbar-light pb-5 pb-xl-0">
              <div class="p-4 text-xl-center mb-4 border-bottom bg-primary text-white position-relative">
                <span class="avatar avatar-md mb-3">
                  <img :src="userInfo.Photo ? `/upload/${userInfo.Photo}` : headerUrl" class="image rounded-circle" >
                </span>
                <div class="h5 fs-16 mb-1 fw-600">{{ userInfo.FullName }}</div>
                <div class="text-truncate opacity-60" v-if="userInfo.Phone">{{ userInfo.Phone }}</div>
                <div class="text-truncate opacity-60" v-else>{{ userInfo.Email }}</div>
              </div>

              <div class="sidemnenu mb-3">
                <ul class="aiz-side-nav-list px-2" data-toggle="aiz-side-menu">
                  <li class="aiz-side-nav-item"  @click.stop="toPath('dashboard')">
                    <a class="aiz-side-nav-link ">
                      <i class="las aiz-side-nav-icon la-home" ></i>
                      <span class="aiz-side-nav-text">{{ $t('shou-ye') }}</span>
                    </a>
                  </li>  
                  <template >
                    <li class="aiz-side-nav-item" v-for="(item, i) in menuList" :key="i" @click.stop="toPath(item.path, item.query)">
                      <a class="aiz-side-nav-link ">
                        <i class="las la aiz-side-nav-icon" :class="item.icon"></i>
                        <span class="aiz-side-nav-text">
                          {{item.name}}
                          <span class="red-point" v-if="item.point && messageCount > 0"></span>
                        </span>
                      </a>
                    </li>                
                  </template>
                </ul>
              </div>

            </div>

            <div class="fixed-bottom d-xl-none bg-white border-top d-flex justify-content-between px-2" style="box-shadow: 0 -5px 10px rgb(0 0 0 / 10%);">
              <a class="btn btn-sm p-2 d-flex align-items-center" @click.stop="logout">
                <i class="las la-sign-out-alt fs-18 mr-2"></i>
                <span>{{ $t('tui-chu') }}</span>
              </a>
              <button class="btn btn-sm p-2 " @click.stop="showMenu">
                <i class="las la-times la-2x"></i>
              </button>
            </div>
          </div>
      </div>
    </div>
</div>
</template>
<script>
import { removeToken, getUserType, removeUserType } from '@/utils/auth'
export default {
  data() {
    return {
      showLeftMenu: false,
      tabIndex: 1,
      headerUrl: require('../../assets/imgs/avatar-place.png'),
      count: 0,
      headerUrl: require('../../assets/imgs/avatar-place.png'),
    }
  },
  computed: {
     userInfo() { 
      return this.$store.state.userInfo
    },
    isLogin() {
      return this.$store.state.isLogin
    },
    noticeList() {
      return this.$store.state.noticeList
    },
    messageCount() {
      return this.$store.state.messageCount
    },
    cartList() {
			return this.$store.state.cartList
		},
    userType() {
      return getUserType()
    },
    menuList() {
      return [
        {
          icon: 'la-file-alt',
          name: this.$t('gou-mai-li-shi'),
          path: 'purchase_history'
        },
        {
          icon: 'la-download',
          name: this.$t('xia-zai-0'),
          path: 'download'
        },
        {
          icon: 'la-backward',
          name: this.$t('yi-fa-song-tui-kuan-qing-qiu'),
          path: 'refund'
        },
        {
          icon: 'la-heart-o',
          name: this.$t('yuan-wang-qing-dan'),
          path: 'wishlist'
        },
        {
          icon: 'la-comment',
          name: this.$t('dui-hua'),
          path: 'conversations',
          point: true
        },
        {
          icon: 'la-dollar-sign',
          name: this.$t('wo-de-qian-bao'),
          path: 'wallet'
        },
        {
          icon: 'la-atom',
          name: this.$t('gong-dan-0'),
          path: 'ticket'
        },
        {
          icon: 'la-atom',
          name: this.$t('mei-ri-ren-wu'),
          path: 'order'
        },
        {
          icon: 'la-atom',
          name: this.$t('ren-wu-ji-lu'),
          path: 'orderList'
        },
        {
          icon: 'la-wallet',
          name: this.$t('jiao-yi-mi-ma-0'),
          path: 'safePassword',
        },
        {
          icon: 'la-user',
          name: this.$t('guan-li-ge-ren-zi-liao'),
          path: 'userInfo'
        },
      ]
    }
  },
  watch: {
    $route() {
      this.changeRoute()
    }
  },
  mounted() {
    this.changeRoute()
  },
  methods: {
    toAccount() {
      if (this.isLogin && (this.tabIndex == 5 || this.tabIndex == 0)) {
        this.showMenu()
        return
      }
      if (this.isLogin) {
        this.$router.push({
          name: this.$store.state.userType == 'custom' ? 'dashboard' : 'sellerDashboard'
        })
      } else {
        this.$router.push({
          name: 'login'
        })
      }
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
      this.showLeftMenu = false
    },
    showMenu() {
      this.showLeftMenu = !this.showLeftMenu
    },
    changeRoute() {
      let name = this.$route.name
      let list = ['index', 'category', 'cart', 'notice', this.userType == 'custom' ? 'dashboard' : 'sellerDashboard']
      let index = list.findIndex(v => {
        return v == name
      })
      this.tabIndex = index + 1
    },
    logout() {
      this.showLeftMenu = false
      removeToken()
      removeUserType()
      this.$store.state.isLogin = false
      this.$router.push({
        name: 'index'
      })
    }
  }
}
</script>