<template>
  <div class=" aiz-main-wrapper d-flex flex-column">
    <topBar></topBar>
    <headerBar></headerBar>
    <transition :name="transitionName">
      <router-view></router-view>
    </transition>
    <tabBar></tabBar>
    <bottomBar></bottomBar>
    <footerLine></footerLine>
    <footerBar></footerBar>
    <Popup v-model="showPopup" @close="cancel">
      <div class="popup-box  big">
        <div class="">
          <div class="modal-content">
            <button type="button" class="close absolute-top-right btn-icon close z-1" data-dismiss="modal"  @click="cancel">
              <span aria-hidden="true" class="la-2x">×</span>
            </button>
            <div class="">    
              <div class="modal-body p-4 c-scrollbar-light">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="row gutters-10 flex-row-reverse">

                      <div class="col">
                        <div class="aiz-carousel product-gallery" data-nav-for='.product-gallery-thumb' data-fade='true' data-auto-height='true'>
                          <div class="carousel-box img-zoom rounded" > 
                            <img class="img-fluid lazyload" :src="bannerUrl" >
                          </div>
                        </div>
                      </div>

                      <div class="col-auto w-90px">
                        <div class="aiz-carousel slick-initialized product-gallery-thumb slick-vertical relative" data-vertical="true">
                          <button type="button" class="slick-prev slick-arrow slick-disabled" @click="bannerPrev" v-if="bannerList.length > 4">
                            <i class="las la-angle-left"></i>
                          </button>
                          <Swipe ref="banner" @change="bannerChange" class="product-banner"  :touchable="touchable" :show-indicators="false" :vertical="true" :width="70" :height="75" :loop="false" >
                            <SwipeItem  v-for="(item, i) in bannerList" :key="i" :class="bannerIndex == i ? 'slick-current' : ''" @click="changeBanner(i)">
                              <div class="carousel-box c-pointer border p-1 rounded" >
                                <img class="lazyload mw-100 size-60px mx-auto"  :src="item" >
                              </div>
                            </SwipeItem>
                          </Swipe>
                          <button type="button" class="slick-next slick-arrow" @click="bannerNext" v-if="bannerList.length > 4">
                            <i class="las la-angle-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="text-left">
                      <h2 class="mb-2 fs-20 fw-600">
                        {{  goodsInfo.Name  }}
                      </h2>

                      <div class="row no-gutters mt-3" v-if="!goodsInfo.oldPrice">
                        <div class="col-2">
                          <div class="opacity-50">{{ $t('jia-ge-0') }}</div>
                        </div>
                        <div class="col-10">
                          <div class="">
                            <strong class="h2 fw-600 text-primary">
                              {{getGoodsPrice()}}
                            </strong>
                          </div>
                        </div>
                      </div>

                      <hr>

                      <form id="option-choice-form">
                        <div v-for="(option, i) in optionList" :key="i">
                          <div class="row no-gutters" >
                            <div class="col-sm-2">
                              <div class="opacity-50 my-2">
                                {{ option.skuPropertyName }}:
                              </div>
                            </div>
                            <div class="col-sm-10">
                              <div class="aiz-radio-inline">
                                <label class="aiz-megabox pl-0 mr-2" v-for="(label, index) in option.skuPropertyValues" :key="`label${index}`">
                                  <input type="radio"  :name="option.name" :checked="option.optionIndex == index" @click="changeSize(i, index)">
                                  <span  class="aiz-megabox-elem rounded d-flex align-items-center justify-content-center py-2 px-3 mb-2">
                                    {{ label.propertyValueDisplayName }}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <hr>
                        </div>

                        <div class="row no-gutters">
                          <div class="col-sm-2">
                            <div class="opacity-50 my-2">{{ $t('shu-liang') }}</div>
                          </div>
                          <div class="col-sm-10">
                            <div class="product-quantity d-flex align-items-center">
                              <div class="row no-gutters align-items-center aiz-plus-minus mr-3"  style="width: 130px;">
                                <button class="btn col-auto btn-icon btn-sm btn-circle btn-light" type="button"  @click="minusCount">
                                  <i class="las la-minus"></i>
                                </button>
                                <input type="number" v-model="goodsCount"  class="col border-0 text-center flex-grow-1 fs-16 input-number"   >
                                <button class="btn  col-auto btn-icon btn-sm btn-circle btn-light" type="button" @click="addCount">
                                  <i class="las la-plus"></i>
                                </button>
                              </div>
                              <div class="avialable-amount opacity-60">
                                 (<span id="available-quantity">{{goodsInfo.Stock}}{{ $t('ke-yong-de') }}</span>)
                              </div>
                            </div>
                          </div>
                        </div> 

                        <hr>

                        <div class="row no-gutters pb-3 " id="chosen_price_div">
                          <div class="col-sm-2">
                            <div class="opacity-50 my-2">{{ $t('zong-jia') }}</div>
                          </div>
                          <div class="col-sm-10">
                            <div class="product-price">
                              <strong id="chosen_price" class="h4 fw-600 text-primary">
                                {{getGoodsPrice(true)}}
                              </strong>
                            </div>
                          </div>
                        </div>

                      </form>


                      <div class="mt-3">
                        <button type="button" class="btn btn-primary buy-now fw-600 add-to-cart" @click="submit">
                          <i class="la la-cart-arrow-down"></i>{{ $t('tian-jia-dao-gou-wu-che') }}
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Popup>
    <div v-if="showKefuModal">
      <messageModal ></messageModal>
    </div>
  </div>
</template>
<script>
import messageModal from '@/components/message'
import {Swipe, SwipeItem, Popup} from 'vant'
import tabBar from '@/components/tabbar'
import footerLine from '@/components/footer'
import footerBar from '@/components/footerBar'
import bottomBar from '@/components/bottomBar'
import topBar from '@/components/topBar'
import headerBar from '@/components/headerBar'
import { addCart, getCategoryList, getCartList } from '@/api/index'
import { resetPrice } from '@/utils/common'
export default {
  name: 'home',
  components: {
    tabBar,
    footerLine,
    footerBar,
    bottomBar,
    headerBar,
    messageModal,
    topBar,
    Popup,
    Swipe,
    SwipeItem
  },
  data() {
    return {
      transitionName: '',
      showPopup: false,
      bannerList: [],
      bannerUrl: '',
      optionList: [],
      optionPriceList: [],
      bannerIndex: 0,
      goodsCount: 1,
      optionIds: ''
    }
  },
  computed: {
    openCart() {
      return this.$store.state.openCart
    },
    goodsInfo() {
      return this.$store.state.goodsInfo
    },
    clearModal() {
      return this.$store.state.clearModal
    },
    touchable() {
      return this.$store.state.touchable
    },
    showKefuModal() {
      return this.$store.state.showKefuModal
    },
  },
  watch: {
    $route(to, from) {
      if (to.meta.index < from.meta.index) {
        this.transitionName = 'slide-right'
      } else if (to.meta.index > from.meta.index) {
        this.transitionName = 'slide-left'
      }
    },
    openCart(val) {
      if (val) {
        this.openModal()
      }
    },
    clearModal(val) {
      if (val) {
        this.cancel()
        setTimeout(() => {
          this.$store.state.clearModal = false
        }, 1500)
      }
    }
  },
  mounted() {
    //客户页面全局数据，在home里面获取
    this.init()
  },
  methods: {
    resetPrice,
    init() {
      getCategoryList().then(res => {
        let categoryList = this.resetCategory(res.data.Items, 0)
        this.$store.state.categoryList = categoryList
      })
    },
    resetCategory(list, parentId) {
      let treeData = []
      list.forEach(v => {
        if (v.Pid == parentId) {
          let otherList = list.filter(val => {
            return val.Pid != parentId
          })
          let children = this.resetCategory(otherList, v.ID)
          if (children.length > 0) {
            v.children = children
          }
          treeData.push(v)
        }
      })
      return treeData
    },
    openModal() {
      this.showPopup = true
      this.bannerList = JSON.parse(this.goodsInfo.ImgUrls)
      // 图片
      if (this.bannerList.length > 0) {
        this.bannerUrl = this.bannerList[0]
        this.bannerIndex = 0
      } else {
        this.bannerUrl = this.goodsInfo.ImgUrl
      }
      // 规格
      let SaleSpecifications = JSON.parse(this.goodsInfo.SaleSpecifications)
      if (SaleSpecifications.list && SaleSpecifications.list.length > 0) {
        this.optionList = SaleSpecifications.list.map(v => {
          v.optionIndex = 0
          return v
        })
        this.optionPriceList = JSON.parse(JSON.stringify(SaleSpecifications.price))
      } else {
        this.optionList = []
        this.optionPriceList = []
      }
      
      setTimeout(() => {
        this.$store.state.openCart = false
      }, 1500)
    },
    cancel() {
      this.showPopup = false
      this.$store.state.openCart = false
    },
    bannerPrev() {
      this.resetIndex = false
      let length = this.bannerList.length
      if (this.bannerIndex < 1) {
        return
      }
      if (length - this.bannerIndex < 4) {
        this.bannerIndex -= 1
        this.bannerUrl = this.bannerList[this.bannerIndex]
      } else {
        this.$refs.banner.prev()
      }
    },
    bannerNext() {
      this.resetIndex = false
      let length = this.bannerList.length
      if (this.bannerIndex >= length - 1) {
        return
      }
      if (length - this.bannerIndex > 4) {
        this.$refs.banner.next()
      } else {
        this.bannerIndex += 1
        this.bannerUrl = this.bannerList[this.bannerIndex]
      }
    },
    bannerChange(i) {
      this.bannerIndex = i
      this.bannerUrl = this.bannerList[this.bannerIndex]
      if (i != this.indexBak && this.resetIndex) {
        this.changeBanner(this.indexBak)
      }
    },
    changeBanner(i) {
     
      let length = this.bannerList.length
      if (length - this.bannerIndex > 4) {
         this.$refs.banner.swipeTo(i)
         this.indexBak = i
         this.resetIndex = true
      } else {
        this.bannerIndex = i
        this.bannerUrl = this.bannerList[this.bannerIndex]
      }
    },
    minusCount() {
      if (this.goodsCount <= 1) {
        return
      }
      this.goodsCount -=1
    },
    addCount() {
      if (this.goodsCount > this.goodsInfo.Stock) {
        return
      }
      this.goodsCount += 1
    },
    submit() {
      let form = new FormData()
      form.append('GoodsId', this.goodsInfo.ID)
      form.append('Attribute', 1)
      form.append('optionIds', this.optionIds)
      addCart(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('tian-jia-cheng-gong'))
          this.cancel()
          this.initCart()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    initCart() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 100)
      getCartList(form).then(res => {
				this.$store.state.cartList = res.data.Items.filter(v => {
          return v.Attribute == 1
        })
				this.$store.state.wishList = res.data.Items.filter(v => {
          return v.Attribute == 2
        })
			})
		},
    getGoodsPrice(isTotal) {
      let ids = this.optionList.map(v => {
        return v.skuPropertyValues[v.optionIndex].propertyValueId
      }).join()
      let price = this.optionPriceList.filter(v => {
        return v.skuPropIds == ids
      })
      this.optionIds = ids
      let count = 1
      if (isTotal) {
        count = this.goodsCount
      }
      if (price.length > 0) {
        return resetPrice(parseFloat(price[0].skuVal.skuAmount.value) * count)
      } else {
        return resetPrice(parseFloat(this.goodsInfo.Price) * count)
      }
    },
    changeSize(i, index) {
      this.$set(this.optionList[i], 'optionIndex', index)
    }
  }
}
</script>